import { FC } from 'react';
import { IFeature } from '../interfaces/features.interface';
import { ISelection } from '../interfaces/selection.interface';
import { IProjectResource } from '../interfaces/project-resource.interface';
import { formatPrice, longestString } from '../utils/helpers';
import { Type } from '../utils/enums';
import { Box, Text, Input, Heading, Flex } from '@chakra-ui/react';

interface IProjectResourcesProps {
  features: { [key: string]: IFeature };
  selection: { [key: string]: ISelection };
  projectResources: { [key: string]: IProjectResource };
  baseDays: number;
  totalPrice: number;
  updateCalc: any;
  resetCalc: any;
}

interface IResource {
  name: string;
  id: string;
  cost: number;
  rate: number;
  allocationCount: any;
  allocationPercent: any;
  allocationKey: string;
}

const ProjectResources: FC<IProjectResourcesProps> = (props) => {
  const {
    features,
    selection,
    projectResources,
    baseDays,
    totalPrice,
    updateCalc,
    resetCalc,
  } = props;

  const resourceIds = Object.keys(projectResources || {});
  const selectionIds = Object.keys(selection || {});
  const resourceTable: Partial<IResource>[] = [];

  let days = 0;
  // calculate the total days
  selectionIds.forEach((id) => {
    selection[id].features.forEach((feature) => {
      if (!features[feature].design) {
        days += features[feature].days;
      }
    });
  });

  resourceIds.forEach((key) => {
    const resource: Partial<IResource> = {
      name: projectResources[key].role,
      id: key,
      cost: 0,
    };

    selectionIds.forEach((id) => {
      selection[id].features.forEach((feature) => {
        if (features[feature].design && projectResources[key].design) {
          const daysAvg = (baseDays * features[feature].days) / 100;
          resource.cost = daysAvg * projectResources[key].rate;
          resource.rate = projectResources[key].rate;
        }

        if (!features[feature].design && !projectResources[key].design) {
          projectResources[key].allocation?.forEach((allocation) => {
            if (allocation.sizeOfApp.id === feature) {
              if (allocation.resourceCount || allocation.resourceCount === 0) {
                const devBase = allocation.resourceCount * baseDays;
                const devExtra = days - baseDays;
                resource.cost =
                  (devBase + devExtra) * projectResources[key].rate * 8;
                resource.rate = projectResources[key].rate;
                resource.allocationCount = allocation.resourceCount;
                resource.allocationKey = allocation.uniqueKey;
              }

              if (allocation.percentage || allocation.percentage === 0) {
                resource.cost =
                  ((days * allocation.percentage) / 100) *
                  projectResources[key].rate *
                  8;
                resource.rate = projectResources[key].rate;
                resource.allocationPercent = allocation.percentage;
                resource.allocationKey = allocation.uniqueKey;
              }
            }
          });
        }
      });
    });

    resourceTable.push(resource);
  });

  resourceTable.sort((a: any, b: any) => {
    if (a.name === 'Developer') {
      return -1;
    }
    if (b.name === 'Developer') {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  const handleUpdate = (
    resource: Partial<IResource>,
    type: any,
    event: any
  ) => {
    const data = {
      featureId: resource.id,
      rate: resource.rate,
      count: resource.allocationCount,
      percent: resource.allocationPercent,
      allocationId: resource.allocationKey,
    };
    const value =
      event.target.value || event.target.value === 0
        ? parseInt(event.target.value, 10)
        : 0;

    switch (type) {
      case Type.resourceRate:
        data.rate = value * 100;
        break;
      case Type.resourceCount:
        data.count = value;
        break;
      case Type.resourcePercent:
        data.percent = value;
        break;
    }

    updateCalc(data, type);
  };

  const priceBoxLength =
    resourceTable.length > 0 ? longestString(resourceTable) + 120 : 120;

  return (
    <>
      <Box className="border-b-2 border-t-2 border-line-grey pt-4 pb-4 total-days ">
        <Box
          className="flex justify-end pr-6"
          style={{ marginRight: priceBoxLength + 70 }}
        >
          <Text as={'h3'} className="mb-2 mr-4">
            Allocation
          </Text>
          <Text as={'h3'} className="mb-2">
            Rate
          </Text>
        </Box>
        {resourceTable.map((resource, index) =>
          resource.rate || resource.rate === 0 ? (
            <Box key={index} className="flex justify-between">
              <Text as={'p'} textAlign="start" className="my-auto">
                {resource.name}
              </Text>
              <Box className="flex">
                {(resource.allocationPercent ||
                  resource.allocationPercent === 0) && (
                  <Box className="flex justify-center min-w-box border-solid border border-dark-grey my-2 ml-1 mr-2 px-2">
                    <Input
                      type="number"
                      width="3rem"
                      textAlign="right"
                      min="0"
                      border="none"
                      outline="none"
                      margin="0"
                      padding="0"
                      _active={{
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      _focus={{
                        border: 'none',
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                      value={resource.allocationPercent}
                      onChange={(e) =>
                        handleUpdate(resource, Type.resourcePercent, e)
                      }
                    />
                    {(resource.allocationPercent ||
                      resource.allocationPercent === 0) && (
                      <Text as={'p'} className="my-auto font-medium">
                        %
                      </Text>
                    )}
                  </Box>
                )}
                {(resource.allocationCount ||
                  resource.allocationCount === 0) && (
                  <Box className="flex min-w-box mb-0 p-2">
                    <Input
                      readOnly
                      type="number"
                      value={resource.allocationCount || 0}
                      min="0"
                      className="text-right outline-none border-solid border border-dark-grey w-20 pr-2"
                      onChange={(e) =>
                        handleUpdate(resource, Type.resourceCount, e)
                      }
                    />
                  </Box>
                )}
                <Box className="flex min-w-box mb-0 p-2">
                  <Input
                    type="number"
                    _focus={{ outline: 'none', boxShadow: 'none' }}
                    borderRadius={'none'}
                    value={resource.rate ? resource.rate / 100 : 0}
                    min="0"
                    className="text-right outline-none border-solid border border-dark-grey w-20 pr-2"
                    onChange={(e) =>
                      handleUpdate(resource, Type.resourceRate, e)
                    }
                  />
                </Box>
                <Box className="flex min-w-box mb-0 p-2">
                  <Text as={'span'} className="border p-2 bg-section-grey">
                    R
                  </Text>
                  <Text
                    as={'p'}
                    className="border border-l-0 p-2 text-right"
                    style={{ minWidth: priceBoxLength }}
                  >
                    {formatPrice(resource.cost, false)}
                  </Text>
                </Box>
              </Box>
            </Box>
          ) : (
            ''
          )
        )}
      </Box>
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        className="pt-8 pb-4"
      >
        <button
          className="hover:text-re-teal hover:underline h-5 mt-6 outline-none"
          onClick={resetCalc}
        >
          Reset to defaults
        </button>
        <Heading as="h2" fontSize={'xx-large'}>
          {formatPrice(totalPrice)}
        </Heading>
      </Flex>
    </>
  );
};

export default ProjectResources;
